import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../../services/AuthService';
import { usePbkAuth } from '../../../contexts/PbkAuthContext';
import { ScriptexAuthStatus } from '../../../models/AuthModels';

const LoginDone: FC<{ navigateDone: () => void }> = (props) => {
  const { navigateDone } = props;
  const navigate = useNavigate();
  const auth = usePbkAuth();
  useEffect(() => {
    AuthService.getRegistrationFlowStatus().then((res) => {
      if (res.data.registrationActions.length !== 0) {
        navigate('/auth/register');
      } else {
        // Safe to assume we're not using FusionAuth on our own auth pages
        auth._implementationData.setAuthStatus((prev: ScriptexAuthStatus) => ({ ...prev, isAuthenticated: true }));
        navigateDone();
      }
    });
  }, [auth._implementationData, navigate, navigateDone]);

  return null;
};

export default LoginDone;
